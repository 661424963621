document.addEventListener('click', function (event) { // event listener for navbar
	// Bail if it's not a .nav-link
	if (!event.target.classList.contains('nav-module')) return;
	// Add the active class
	event.target.classList.add('active');
	// Get all nav links
	var links = document.querySelectorAll('.nav-module');
	// Loop through each link
	for (var i = 0; i < links.length; i++) {

		// If the link is the one clicked, skip it
		if (links[i] === event.target) continue;

		// Remove the .active class
		links[i].classList.remove('active');
	}

}, false);

centralDiv2 = document.querySelector("#central-div-2")
let workExShaw = document.querySelector("#work-ex-shaw")
let workExTata = document.querySelector("#work-ex-tata")
let workExIdc = document.querySelector("#work-ex-idc")
let workExSpa = document.querySelector("#work-ex-spa")


centralDiv2.addEventListener('click', function (event) { // event listener for work ex divs
	// Bail if it's not a .nav-link
	if (!event.target.classList.contains('work-ex-selector')) return;
	// Add the active class
	event.target.classList.add('work-ex-active');
	// Get all nav links
	var links = document.querySelectorAll('.work-ex-selector');
	// Loop through each link
	for (var i = 0; i < links.length; i++) {

		// If the link is the one clicked, skip it
		if (links[i] === event.target) continue;

		// Remove the .active class
		links[i].classList.remove('work-ex-active');

		if(event.target === links[0]) {
			workExShaw.style.display = "block"
			workExTata.style.display = "none"
			workExIdc.style.display = "none"
			workExSpa.style.display = "none"
		} else if (event.target === links[1]) {
			workExShaw.style.display = "none"
			workExTata.style.display = "block"
			workExIdc.style.display = "none"
			workExSpa.style.display = "none"
		} else if (event.target === links[2]) {
			workExShaw.style.display = "none"
			workExTata.style.display = "none"
			workExIdc.style.display = "block"
			workExSpa.style.display = "none"
		} else if (event.target === links[3]) {
			workExShaw.style.display = "none"
			workExTata.style.display = "none"
			workExIdc.style.display = "none"
			workExSpa.style.display = "block"
		}

		// if(event.target === links[0]) {
		// 	workExShaw.style.cssText += 'position:relative;'
		// 	workExTata.style.cssText = 'opacity:0 !important; position:absolute; right:0; transition:2s;'
		// 	workExIdc.style.cssText = 'opacity:0 !important; position:absolute;  right:0; transition:2s;'
		// 	workExSpa.style.cssText = "opacity:0 !important; position:absolute;  right:0; transition:2s;"
		// } else if (event.target === links[1]) {
		// 	workExShaw.style.cssText = "opacity:0 !important; position:absolute;  right:0; transition:2s;"
		// 	workExTata.style.cssText = 'position:relative;'
		// 	workExIdc.style.cssText = "opacity:0 !important; position:absolute;  right:0; transition:2s;"
		// 	workExSpa.style.cssText = "opacity:0 !important; position:absolute;  right:0; transition:2s;"
		// } else if (event.target === links[2]) {
		// 	workExShaw.style.cssText = "opacity:0 !important; position:absolute;  right:0; transition:2s;"
		// 	workExTata.style.cssText = "opacity:0 !important; position:absolute;  right:0e; transition:2s;"
		// 	workExIdc.style.cssText = 'position:relative;'
		// 	workExSpa.style.cssText = "opacity:0 !important; position:absolute;  right:0; transition:2s;"
		// } else if (event.target === links[3]) {
		// 	workExShaw.style.cssText = "opacity:0 !important; position:absolute;  right:0; transition:2s;"
		// 	workExTata.style.cssText = "opacity:0 !important; position:absolute;  right:0; transition:2s;"
		// 	workExIdc.style.cssText = "opacity:0 !important; position:absolute;  right:0; transition:2s;"
		// 	workExSpa.style.cssText = 'position:relative;'
		// }
	}
}, false);

// intersection observer below

const observerOptions = {
	root: null,
	threshold: [0.06, 0.3, 0.4, 0.5, 0.6, 0.65, 0.7, 0.8, 0.85, 0.9, 0.95], // add any new intersection ratio values here
	rootMargin: '0px'
};

let observerHiJribh = new IntersectionObserver(intersectionHandlerHiJribh, observerOptions);
let observerWorkEx = new IntersectionObserver(intersectionHandlerWorkEx, observerOptions);
let observerSkills = new IntersectionObserver(intersectionHandlerSkills, observerOptions);
let observerProjectContainer = new IntersectionObserver(intersectionHandlerProjectContainer, observerOptions);
let observerProject = new IntersectionObserver(intersectionHandlerProject, observerOptions);
let observerAlsoCheck = new IntersectionObserver(intersectionHandlerAlsoCheck, observerOptions);
let observerLetsConnect = new IntersectionObserver(intersectionHandlerLetsConnect, observerOptions);

document.querySelectorAll("#central-div-1 > .central-left-div").forEach((row, index) => {
	observerHiJribh.observe(row);
});

document.querySelectorAll("#central-div-2 > .central-left-div").forEach((row, index) => {
	observerWorkEx.observe(row);
});

document.querySelectorAll("#central-div-3").forEach((row, index) => {
	observerSkills.observe(row);
});

document.querySelectorAll("#project-container").forEach((row, index) => {
	observerProjectContainer.observe(row);
});

document.querySelectorAll(".project").forEach((row, index) => {
	observerProject.observe(row);
});

document.querySelectorAll("#project-container .projects-width:nth-of-type(2)").forEach((row, index) => {
	observerAlsoCheck.observe(row);
});

document.querySelectorAll("#project-container .projects-width:nth-of-type(3)").forEach((row, index) => {
	observerLetsConnect.observe(row);
});


function intersectionHandlerHiJribh(entries, observerHiJribh) {
	entries.forEach((entry) => {
		intersectionConditional(entry, document.querySelector("#central-div-1 > .central-left-div"), 0.9) // replace second param with entry.target to fade-in entire target
		intersectionConditional(entry, document.querySelector("#central-div-1 > .central-right-div"), 0.7)
		intersectionConditional(entry, document.querySelector("#bg-banner-1"), 0.5)

		setTimeout(function() {
			changeNavModule(entry, 0.1)
		}, 500)

	});
};

function intersectionHandlerWorkEx(entries, observerWorkEx) {
	entries.forEach((entry) => {
		intersectionConditional(entry, document.querySelector("#central-div-2 > .central-left-div > .title"), 0.5) // replace second param with entry.target to fade-in entire target
		intersectionConditional(entry, document.querySelector("#central-div-2 > .central-right-div > .div-right-nos"), 0.6)
		intersectionConditional(entry, document.querySelector("#work-exs"), 0.8)
		intersectionConditional(entry, document.querySelector("#central-div-2 > .central-left-div > .button"), 0.9)
		intersectionConditional(entry, document.querySelector("#central-div-2 > .central-right-div > .div-right-desc"), 0.85)
		intersectionConditional(entry, document.querySelector("#bg-banner-2"), 0.7)


		setTimeout(function() {
			changeNavModule(entry, 0.1)
		}, 500)	});
};

function intersectionHandlerSkills(entries, observerSkills) {
	entries.forEach((entry) => {
		intersectionConditional(entry, document.querySelector("#central-div-3 > .central-left-div > .title"), 0.5)
		intersectionConditional(entry, document.querySelector("#skills-div > .skill-div:nth-of-type(1)"), 0.7)
		intersectionConditional(entry, document.querySelector("#skills-div > .skill-div:nth-of-type(2)"), 0.8)
		intersectionConditional(entry, document.querySelector("#skills-div > .skill-div:nth-of-type(3)"), 0.9)
		intersectionConditional(entry, document.querySelector("#central-div-3 > #bg-banner-3"), 0.95)


		setTimeout(function() {
			changeNavModule(entry, 0.1)
		}, 500)	});
};

function intersectionHandlerProjectContainer(entries, observerProjectContainer) {
	entries.forEach((entry) => {
		intersectionConditional(entry, document.querySelector("#project-container > .projects-width > .projects-header"), 0.06)


		setTimeout(function() {
			changeNavModule(entry, 0.1)
		}, 500)	});
};

function intersectionHandlerProject(entries, observerProject) {
	entries.forEach((entry) => {
		intersectionConditional(entry, entry.target, 0.3)


		setTimeout(function() {
			changeNavModule(entry, 0.1)
		}, 500)	});
};

function intersectionHandlerAlsoCheck(entries, observerAlsoCheck) {
	entries.forEach((entry) => {
		intersectionConditional(entry, document.querySelector("#project-container > .projects-width:nth-of-type(2) > .projects-header"), 0.5)
		intersectionConditional(entry, document.querySelector("#also-check > .also-check-child:nth-of-type(1)"), 0.6)
		intersectionConditional(entry, document.querySelector("#also-check > .also-check-child:nth-of-type(2)"), 0.65)


		setTimeout(function() {
			changeNavModule(entry, 0.1)
		}, 500)	});
};

function intersectionHandlerLetsConnect(entries, observerLetsConnect) {
	entries.forEach((entry) => {
		intersectionConditional(entry, document.querySelector("#project-container > .projects-width:nth-of-type(3) > .projects-header"), 0.3)
		intersectionConditional(entry, document.querySelector("#contact-form"), 0.4)


		setTimeout(function() {
			changeNavModule(entry, 0.1)
		}, 500)	});
};

// class toggle function

function intersectionConditional(entry, element, threshold) {
	if (entry.isIntersecting && entry.intersectionRatio > threshold) { // make more of these ifs with different intersection ratios for different divs
		if(entry.target.getBoundingClientRect().top >= window.innerHeight/3 || entry.target.getBoundingClientRect().bottom > 0) { // means container is moving in from bottom
			element.classList.remove("div-fade-in", "div-fade-out")
			element.classList.add("div-in-view")
		} else { // means container is going out from top
			element.classList.remove("div-fade-in", "div-in-view")
			element.classList.add("div-fade-out")
		}
	} else {
		if(entry.target.getBoundingClientRect().top < window.innerHeight/3) { // means container is out of bounds, on top
			element.classList.remove("div-fade-in", "div-in-view")
			element.classList.add("div-fade-out")
		} else { // means container is out of bounds, in bottom
			element.classList.remove("div-fade-out", "div-in-view")
			element.classList.add("div-fade-in")
		}
	}
}

// changing scroll speeds
function moveIt() {
	var instances = [];
	var elements = document.querySelectorAll('[data-scroll-speed]');
  
	elements.forEach(function (element) {
	  instances.push(new MoveItItem(element));
	});
  
	window.addEventListener('scroll', function () {
	  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	  instances.forEach(function (inst) {
		inst.update(scrollTop);
	  });
	}, { passive: true });
}
  
function MoveItItem(el) {
this.el = el;
this.speed = parseInt(this.el.getAttribute('data-scroll-speed'));
}
  
MoveItItem.prototype.update = function (scrollTop) {
this.el.style.transform = 'translateY(' + -(scrollTop / this.speed) + 'px)';
};
  
// Initialization
window.addEventListener('DOMContentLoaded', function () {
	moveIt();
});


// change nav module in intersection

function changeNavModule(entry, threshold) {
	let home = document.querySelector("#nav-right-panel > li:nth-of-type(1) > a")
	let about = document.querySelector("#nav-right-panel > li:nth-of-type(2) > a")
	let projects = document.querySelector("#nav-right-panel > li:nth-of-type(3) > a")
	let contact = document.querySelector("#nav-right-panel > li:nth-of-type(4) > a")

	if (entry.isIntersecting && entry.intersectionRatio > threshold) { // make more of these ifs with different intersection ratios for different divs
		if(document.querySelector("#central-div-1").getBoundingClientRect().bottom >= 20) {
			home.classList.add("active")
			about.classList.remove("active")
			projects.classList.remove("active")
			contact.classList.remove("active")
		} else if (document.querySelector("#central-div-2").getBoundingClientRect().bottom >= 0) {
			home.classList.remove("active")
			about.classList.add("active")
			projects.classList.remove("active")
			contact.classList.remove("active")
		} else if (document.querySelector("#project-container > .projects-width:nth-of-type(1)").getBoundingClientRect().bottom >= 0) {
			home.classList.remove("active")
			about.classList.remove("active")
			projects.classList.add("active")
			contact.classList.remove("active")
		} else if (document.querySelector("#project-container > .projects-width:nth-of-type(3)").getBoundingClientRect().top < window.innerHeight) {
			home.classList.remove("active")
			about.classList.remove("active")
			projects.classList.remove("active")
			contact.classList.add("active")
		}
	}
}

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});



// navbar for mobile

let navRightPanel = document.querySelector("#nav-right-panel");
let navCloseIcon = document.querySelector("#nav-close-icon");
let hamburgerIcon = document.querySelector("#top-nav-hamburger");

hamburgerIcon.addEventListener("click", ()=> {
	navRightPanel.classList.remove("nav-right-panel-hidden")
})

navCloseIcon.addEventListener("click", ()=> {
	navRightPanel.classList.add("nav-right-panel-hidden")
})


// // for dialog projects

const dialogs = document.querySelectorAll(".dialogs");
const dialogCloseButtons = document.querySelectorAll(".project-dialog-close-icon");

// "Show the dialog" for spacetime dialog
document.querySelector("#project-spacetime").addEventListener("click", () => {
	document.querySelector("#spacetime-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#spacetime-next-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#spacetime-dialog").classList.add('hide');
	document.querySelector("#spacetime-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#spacetime-dialog").classList.remove('hide');
		document.querySelector("#spacetime-dialog").close();
		document.querySelector("#spacetime-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#omni-prev-project-button").addEventListener("click", () => { // open dialog when coming from modal after
	document.querySelector("#spacetime-dialog").showModal();
	document.body.classList.add('dialog-open');
});

// "Show the dialog" for omni dialog
document.querySelector("#project-omni").addEventListener("click", () => {
	document.querySelector("#omni-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#omni-next-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#omni-dialog").classList.add('hide');
	document.querySelector("#omni-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#omni-dialog").classList.remove('hide');
		document.querySelector("#omni-dialog").close();
		document.querySelector("#omni-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#omni-prev-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#omni-dialog").classList.add('hide');
	document.querySelector("#omni-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#omni-dialog").classList.remove('hide');
		document.querySelector("#omni-dialog").close();
		document.querySelector("#omni-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#spacetime-next-project-button").addEventListener("click", () => { // open dialog when coming from modal before
	document.querySelector("#omni-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#iks-prev-project-button").addEventListener("click", () => { // open dialog when coming from modal after
	document.querySelector("#omni-dialog").showModal();
	document.body.classList.add('dialog-open');
});

// "Show the dialog" for iks dialog
document.querySelector("#project-iks").addEventListener("click", () => {
	document.querySelector("#iks-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#iks-next-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#iks-dialog").classList.add('hide');
	document.querySelector("#iks-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#iks-dialog").classList.remove('hide');
		document.querySelector("#iks-dialog").close();
		document.querySelector("#iks-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#iks-prev-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#iks-dialog").classList.add('hide');
	document.querySelector("#iks-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#iks-dialog").classList.remove('hide');
		document.querySelector("#iks-dialog").close();
		document.querySelector("#iks-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#omni-next-project-button").addEventListener("click", () => { // open dialog when coming from modal before
	document.querySelector("#iks-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#remote-prev-project-button").addEventListener("click", () => { // open dialog when coming from modal after
	document.querySelector("#iks-dialog").showModal();
	document.body.classList.add('dialog-open');
});

// "Show the dialog" for remote-touch dialog
document.querySelector("#project-remote-touch").addEventListener("click", () => {
	document.querySelector("#remote-touch-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#remote-next-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#remote-touch-dialog").classList.add('hide');
	document.querySelector("#remote-touch-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#remote-touch-dialog").classList.remove('hide');
		document.querySelector("#remote-touch-dialog").close();
		document.querySelector("#remote-touch-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#remote-prev-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#remote-touch-dialog").classList.add('hide');
	document.querySelector("#remote-touch-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#remote-touch-dialog").classList.remove('hide');
		document.querySelector("#remote-touch-dialog").close();
		document.querySelector("#remote-touch-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#iks-next-project-button").addEventListener("click", () => { // open dialog when coming from modal before
	document.querySelector("#remote-touch-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#songs-prev-project-button").addEventListener("click", () => { // open dialog when coming from modal after
	document.querySelector("#remote-touch-dialog").showModal();
	document.body.classList.add('dialog-open');
});

// "Show the dialog" for songs dialog
document.querySelector("#project-songs").addEventListener("click", () => {
	document.querySelector("#songs-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#songs-next-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#songs-dialog").classList.add('hide');
	document.querySelector("#songs-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#songs-dialog").classList.remove('hide');
		document.querySelector("#songs-dialog").close();
		document.querySelector("#songs-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#songs-prev-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#songs-dialog").classList.add('hide');
	document.querySelector("#songs-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#songs-dialog").classList.remove('hide');
		document.querySelector("#songs-dialog").close();
		document.querySelector("#songs-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#remote-next-project-button").addEventListener("click", () => { // open dialog when coming from modal before
	document.querySelector("#songs-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#createnshare-prev-project-button").addEventListener("click", () => { // open dialog when coming from modal after
	document.querySelector("#songs-dialog").showModal();
	document.body.classList.add('dialog-open');
});

// "Show the dialog" for createnshare dialog
document.querySelector("#project-createshare").addEventListener("click", () => {
	document.querySelector("#createnshare-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#createnshare-next-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#createnshare-dialog").classList.add('hide');
	document.querySelector("#createnshare-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#createnshare-dialog").classList.remove('hide');
		document.querySelector("#createnshare-dialog").close();
		document.querySelector("#createnshare-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#createnshare-prev-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#createnshare-dialog").classList.add('hide');
	document.querySelector("#createnshare-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#createnshare-dialog").classList.remove('hide');
		document.querySelector("#createnshare-dialog").close();
		document.querySelector("#createnshare-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#songs-next-project-button").addEventListener("click", () => { // open dialog when coming from modal before
	document.querySelector("#createnshare-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#bonfire-prev-project-button").addEventListener("click", () => { // open dialog when coming from modal after
	document.querySelector("#createnshare-dialog").showModal();
	document.body.classList.add('dialog-open');
});

// "Show the dialog" for bonfire dialog
document.querySelector("#project-bonfire").addEventListener("click", () => {
	document.querySelector("#bonfire-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#bonfire-next-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#bonfire-dialog").classList.add('hide');
	document.querySelector("#bonfire-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#bonfire-dialog").classList.remove('hide');
		document.querySelector("#bonfire-dialog").close();
		document.querySelector("#bonfire-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#bonfire-prev-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#bonfire-dialog").classList.add('hide');
	document.querySelector("#bonfire-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#bonfire-dialog").classList.remove('hide');
		document.querySelector("#bonfire-dialog").close();
		document.querySelector("#bonfire-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#createnshare-next-project-button").addEventListener("click", () => { // open dialog when coming from modal before
	document.querySelector("#bonfire-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#guardian-prev-project-button").addEventListener("click", () => { // open dialog when coming from modal after
	document.querySelector("#bonfire-dialog").showModal();
	document.body.classList.add('dialog-open');
});

// "Show the dialog" for guardian dialog
document.querySelector("#project-guardian").addEventListener("click", () => {
	document.querySelector("#guardian-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#guardian-next-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#guardian-dialog").classList.add('hide');
	document.querySelector("#guardian-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#guardian-dialog").classList.remove('hide');
		document.querySelector("#guardian-dialog").close();
		document.querySelector("#guardian-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#guardian-prev-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#guardian-dialog").classList.add('hide');
	document.querySelector("#guardian-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#guardian-dialog").classList.remove('hide');
		document.querySelector("#guardian-dialog").close();
		document.querySelector("#guardian-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#bonfire-next-project-button").addEventListener("click", () => { // open dialog when coming from modal before
	document.querySelector("#guardian-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#coach-prev-project-button").addEventListener("click", () => { // open dialog when coming from modal after
	document.querySelector("#guardian-dialog").showModal();
	document.body.classList.add('dialog-open');
});

// "Show the dialog" for coach dialog
document.querySelector("#project-coach").addEventListener("click", () => {
	document.querySelector("#coach-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#coach-next-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#coach-dialog").classList.add('hide');
	document.querySelector("#coach-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#coach-dialog").classList.remove('hide');
		document.querySelector("#coach-dialog").close();
		document.querySelector("#coach-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#coach-prev-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#coach-dialog").classList.add('hide');
	document.querySelector("#coach-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#coach-dialog").classList.remove('hide');
		document.querySelector("#coach-dialog").close();
		document.querySelector("#coach-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#guardian-next-project-button").addEventListener("click", () => { // open dialog when coming from modal before
	document.querySelector("#coach-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#genocorp-prev-project-button").addEventListener("click", () => { // open dialog when coming from modal after
	document.querySelector("#coach-dialog").showModal();
	document.body.classList.add('dialog-open');
});

// "Show the dialog" for genocorp dialog
document.querySelector("#project-genocorp").addEventListener("click", () => {
	document.querySelector("#genocorp-dialog").showModal();
	document.body.classList.add('dialog-open');
});

document.querySelector("#genocorp-next-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#genocorp-dialog").classList.add('hide');
	document.querySelector("#genocorp-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#genocorp-dialog").classList.remove('hide');
		document.querySelector("#genocorp-dialog").close();
		document.querySelector("#genocorp-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#genocorp-prev-project-button").addEventListener("click", () => { // close this dialog when user clicks on prev/next project
	document.querySelector("#genocorp-dialog").classList.add('hide');
	document.querySelector("#genocorp-dialog").addEventListener('webkitAnimationEnd', function(){
		document.querySelector("#genocorp-dialog").classList.remove('hide');
		document.querySelector("#genocorp-dialog").close();
		document.querySelector("#genocorp-dialog").removeEventListener('webkitAnimationEnd',  arguments.callee, false);
	}, false);
});

document.querySelector("#coach-next-project-button").addEventListener("click", () => { // open dialog when coming from modal before
	document.querySelector("#genocorp-dialog").showModal();
	document.body.classList.add('dialog-open');
});

// "Show the dialog" for architecture dialog
document.querySelector("#project-architecture").addEventListener("click", () => {
	document.querySelector("#architecture-dialog").showModal();
	document.body.classList.add('dialog-open');
});

// "Close" for all dialogs
for(let i=0; i < dialogCloseButtons.length; i++) {
	dialogCloseButtons[i].addEventListener("click", () => {

		dialogs[i].classList.add('hide');
		dialogs[i].addEventListener('webkitAnimationEnd', function(){
			dialogs[i].classList.remove('hide');
			dialogs[i].close();
			dialogs[i].removeEventListener('webkitAnimationEnd',  arguments.callee, false);
		}, false);

		setTimeout(function() {
			document.body.classList.remove('dialog-open');
		}, 500)
	});
}

//animations on scroll in dialog

for(let i=0; i< dialogs.length; i++) {
	dialogs[i].addEventListener('scroll', function() {

		const scrollYPosition = dialogs[i].scrollTop;
	  
		const projectDialogSubtitles = document.querySelectorAll('.project-dialog-subtitle');
		const projectDialogTops = document.querySelectorAll('.project-dialog-top');
		const projectDialogTitles = document.querySelectorAll('.project-dialog-title');
	  
		if (scrollYPosition > 0) {
			projectDialogSubtitles.forEach(projectDialogSubtitle => {
				projectDialogSubtitle.style.opacity = 0;
			})
			projectDialogTops.forEach(projectDialogTop => {
				projectDialogTop.style.borderBottom = "1px solid rgba(255, 255, 255, 0.12)"
			})
			projectDialogTitles.forEach(projectDialogTitle => {
				projectDialogTitle.style.opacity = "0.8"
			})
		} else {
			projectDialogSubtitles.forEach(projectDialogSubtitle => {
				projectDialogSubtitle.style.opacity = 1;
			})
			projectDialogTops.forEach(projectDialogTop => {
				projectDialogTop.style.borderBottom = "none"
			})
			projectDialogTitles.forEach(projectDialogTitle => {
				projectDialogTitle.style.opacity = "1"
			})
		}
	});
}



// for dialog projects- experiment with single next prev buttons

// const dialogs = document.querySelectorAll(".dialogs");
// const dialogCloseButtons = document.querySelectorAll(".project-dialog-close-icon");

// let currentDialogIndex = 0;

// const prevProjectButtons = document.querySelectorAll(".previous-project");
// const nextProjectButtons = document.querySelectorAll(".next-project");

// for(let i=0; i<prevProjectButtons.length; i++) {
// 	prevProjectButtons[i].addEventListener('click', ()=> {
// 		openPreviousProjectDialog();

// 		setTimeout(function() {
// 			currentDialogIndex = currentDialogIndex - 1;
// 			console.log(currentDialogIndex)
// 		}, 500)
// 	});
// }
// for(let i=0; i<nextProjectButtons.length; i++) {
// 	nextProjectButtons[i].addEventListener('click', ()=> {
// 		openNextProjectDialog();

// 		setTimeout(function() {
// 			currentDialogIndex = currentDialogIndex + 1;
// 			console.log(currentDialogIndex)
// 		}, 500)	});
// }

// function openPreviousProjectDialog() {

// 	// first hide the current dialog

// 	dialogs[currentDialogIndex].classList.add('hide');
// 	dialogs[currentDialogIndex].addEventListener('webkitAnimationEnd', function(){
// 		dialogs[currentDialogIndex].classList.remove('hide');
// 		dialogs[currentDialogIndex].close();
// 		dialogs[currentDialogIndex].removeEventListener('webkitAnimationEnd',  arguments.callee, false);
// 	}, false);

// 	// now open the previous dialog

// 	dialogs[currentDialogIndex - 1].showModal();
// }

// function openNextProjectDialog() {

// 	// first hide the current dialog

// 	dialogs[currentDialogIndex].classList.add('hide');
// 	dialogs[currentDialogIndex].addEventListener('webkitAnimationEnd', function(){
// 		dialogs[currentDialogIndex].classList.remove('hide');
// 		dialogs[currentDialogIndex].close();
// 		dialogs[currentDialogIndex].removeEventListener('webkitAnimationEnd',  arguments.callee, false);
// 	}, false);

// 	// now open the next dialog

// 	dialogs[currentDialogIndex + 1].showModal();
// }


// // "Show the dialog" for spacetime dialog
// document.querySelector("#project-spacetime").addEventListener("click", () => {
// 	document.querySelector("#spacetime-dialog").showModal();
// 	document.body.classList.add('dialog-open');

// 	currentDialogIndex = 0;
// });

// // "Show the dialog" for omni dialog
// document.querySelector("#project-omni").addEventListener("click", () => {
// 	document.querySelector("#omni-dialog").showModal();
// 	document.body.classList.add('dialog-open');

// 	currentDialogIndex = 1;

// });

// // "Show the dialog" for iks dialog
// document.querySelector("#project-iks").addEventListener("click", () => {
// 	document.querySelector("#iks-dialog").showModal();
// 	document.body.classList.add('dialog-open');

// 	currentDialogIndex = 2;

// });

// // "Show the dialog" for remote-touch dialog
// document.querySelector("#project-remote-touch").addEventListener("click", () => {
// 	document.querySelector("#remote-touch-dialog").showModal();
// 	document.body.classList.add('dialog-open');

// 	currentDialogIndex = 3;

// });

// // "Show the dialog" for songs dialog
// document.querySelector("#project-songs").addEventListener("click", () => {
// 	document.querySelector("#songs-dialog").showModal();
// 	document.body.classList.add('dialog-open');

// 	currentDialogIndex = 4;

// });

// // "Show the dialog" for createnshare dialog
// document.querySelector("#project-createshare").addEventListener("click", () => {
// 	document.querySelector("#createnshare-dialog").showModal();
// 	document.body.classList.add('dialog-open');

// 	currentDialogIndex = 5;

// });

// // "Show the dialog" for bonfire dialog
// document.querySelector("#project-bonfire").addEventListener("click", () => {
// 	document.querySelector("#bonfire-dialog").showModal();
// 	document.body.classList.add('dialog-open');

// 	currentDialogIndex = 6;

// });

// // "Show the dialog" for guardian dialog
// document.querySelector("#project-guardian").addEventListener("click", () => {
// 	document.querySelector("#guardian-dialog").showModal();
// 	document.body.classList.add('dialog-open');

// 	currentDialogIndex = 7;

// });

// // "Show the dialog" for coach dialog
// document.querySelector("#project-coach").addEventListener("click", () => {
// 	document.querySelector("#coach-dialog").showModal();
// 	document.body.classList.add('dialog-open');

// 	currentDialogIndex = 8;

// });

// // "Show the dialog" for genocorp dialog
// document.querySelector("#project-genocorp").addEventListener("click", () => {
// 	document.querySelector("#genocorp-dialog").showModal();
// 	document.body.classList.add('dialog-open');

// 	currentDialogIndex = 9;

// });

// // "Show the dialog" for architecture dialog
// document.querySelector("#project-architecture").addEventListener("click", () => {
// 	document.querySelector("#architecture-dialog").showModal();
// 	document.body.classList.add('dialog-open');
// });

// // "Close" for all dialogs
// for(let i=0; i < dialogCloseButtons.length; i++) {
// 	dialogCloseButtons[i].addEventListener("click", () => {

// 		dialogs[i].classList.add('hide');
// 		dialogs[i].addEventListener('webkitAnimationEnd', function(){
// 			dialogs[i].classList.remove('hide');
// 			dialogs[i].close();
// 			dialogs[i].removeEventListener('webkitAnimationEnd',  arguments.callee, false);
// 		}, false);

// 		currentDialogIndex = 0;

// 		setTimeout(function() {
// 			document.body.classList.remove('dialog-open');
// 		}, 500)
// 	});
// }

// //animations on scroll in dialog

// for(let i=0; i< dialogs.length; i++) {
// 	dialogs[i].addEventListener('scroll', function() {

// 		const scrollYPosition = dialogs[i].scrollTop;
	  
// 		const projectDialogSubtitles = document.querySelectorAll('.project-dialog-subtitle');
// 		const projectDialogTops = document.querySelectorAll('.project-dialog-top');
// 		const projectDialogTitles = document.querySelectorAll('.project-dialog-title');
	  
// 		if (scrollYPosition > 0) {
// 			projectDialogSubtitles.forEach(projectDialogSubtitle => {
// 				projectDialogSubtitle.style.opacity = 0;
// 			})
// 			projectDialogTops.forEach(projectDialogTop => {
// 				projectDialogTop.style.borderBottom = "1px solid rgba(255, 255, 255, 0.12)"
// 			})
// 			projectDialogTitles.forEach(projectDialogTitle => {
// 				projectDialogTitle.style.opacity = "0.8"
// 			})
// 		} else {
// 			projectDialogSubtitles.forEach(projectDialogSubtitle => {
// 				projectDialogSubtitle.style.opacity = 1;
// 			})
// 			projectDialogTops.forEach(projectDialogTop => {
// 				projectDialogTop.style.borderBottom = "none"
// 			})
// 			projectDialogTitles.forEach(projectDialogTitle => {
// 				projectDialogTitle.style.opacity = "1"
// 			})
// 		}
// 	});
// }